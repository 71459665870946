import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../configs/routes';
import TradeRouteCreateScene from '../scenes/trade-route/TradeRouteCreateScene';
import TradeRouteEditScene from '../scenes/trade-route/TradeRouteEditScene';
import TradeRouteIndexScene from '../scenes/trade-route/TradeRouteIndexScene';
import TradeRouteShowScene from '../scenes/trade-route/TradeRouteShowScene';

export default (): JSX.Element => {
  return (
    <Switch>
      <Route
        path={routes.TRADE_ROUTE.INDEX}
        exact
        component={TradeRouteIndexScene}
      />
      <Route
        path={routes.TRADE_ROUTE.CREATE}
        exact
        component={TradeRouteCreateScene}
      />
      <Route
        path={routes.TRADE_ROUTE.EDIT}
        exact
        component={TradeRouteEditScene}
      />
      <Route
        path={routes.TRADE_ROUTE.SHOW}
        exact
        component={TradeRouteShowScene}
      />
    </Switch>
  );
};
