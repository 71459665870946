import React from 'react';
import { GenericCreateForm } from 'releox-react';
import TradeRouteForm from '../../forms/trade-route/TradeRouteForm';
import TradeRouteInitialValues from '../../forms/trade-route/TradeRouteInitialValues';
import TradeRouteValidationSchema from '../../forms/trade-route/TradeRouteValidationSchema';
import TradeRoutesAction from '../../store/trade-route/TradeRoutesAction';
import TradeRoutesSelector from '../../store/trade-route/TradeRoutesSelector';

export default (): JSX.Element => {
  return (
    <GenericCreateForm
      tNamespace="TradeRouteCreateScene"
      EmbedForm={TradeRouteForm}
      loadingSelector={TradeRoutesSelector.selectTradeRouteIsLoading}
      validationSchema={TradeRouteValidationSchema}
      initialValues={TradeRouteInitialValues}
      onSubmit={TradeRoutesAction.create}
    />
  );
};
