export default (list: string): number => {
  let total = 0;

  const numbers = list.split(',').map((s) => parseFloat(s));

  const diff = numbers.map((n, i): number | null => {
    if (i === 0) return null;
    return n - numbers[i - 1];
  });

  diff.forEach((n) => {
    if (n === null) return;
    total += n;
  });

  return Math.round((total / (diff.length - 1)) * 100) / 100;
};
