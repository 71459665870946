import { RxDBRequestCreator } from 'redux-rest-helper-for-loopback';
import connectDb, { Database } from '../../db/connect-db';
import TradeRoute from './TradeRoute';

export default class TradeRoutesEffect {
  public static restRequests = RxDBRequestCreator<TradeRoute, Database>(
    connectDb,
    'trade_route'
  );

  public static findById = TradeRoutesEffect.restRequests.getById;

  public static find = TradeRoutesEffect.restRequests.getAll;

  public static create = TradeRoutesEffect.restRequests.create;

  public static update = TradeRoutesEffect.restRequests.update;

  public static delete = TradeRoutesEffect.restRequests.delete;

  public static count = TradeRoutesEffect.restRequests.count;
}
