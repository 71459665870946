export default {
  name: {
    dataField: 'name',
    text: 'Name',
  },

  averageTradeTime: {
    dataField: 'averageTradeTime',
    text: 'Average travel time',
  },

  tradeTimeList: {
    dataField: 'tradeTimeList',
    text: 'Time list',
  },
};
