import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { routerMiddleware } from 'react-router-redux';
import { Rednot } from 'rednot';
import 'rednot/src/styles/index.css';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRouter from './routers/AppRouter';
import rootReducer, { ReducerReturn } from './store/rootReducer';

const history = createBrowserHistory();

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type ReduxState = ReducerReturn;
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunk, routerMiddleware(history)))
);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <AppRouter />
    </Router>
    <Rednot />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
