import TradeRoute from './TradeRoute';

export default class TradeRoutesSelector {
  public static selectTradeRoute(state: ReduxState): TradeRoute {
    return state.tradeRoute.model.data;
  }

  public static selectTradeRoutes(state: ReduxState): TradeRoute[] {
    return state.tradeRoute.list.data;
  }

  public static selectTradeRouteIsLoading(state: ReduxState): boolean {
    return state.tradeRoute.model.isLoading;
  }

  public static selectTradeRoutesIsLoading(state: ReduxState): boolean {
    return state.tradeRoute.list.isFetchLoading;
  }

  public static selectTradeRoutesCount(state: ReduxState): number {
    return state.tradeRoute.list.count;
  }
}
