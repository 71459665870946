/* eslint-disable camelcase,@typescript-eslint/no-explicit-any */

import dbAdapter from 'pouchdb-adapter-idb';
import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import TradeRouteCollection, {
  TradeRouteCollectionType,
} from './collections/TradeRouteCollection';

addRxPlugin(RxDBValidatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(dbAdapter);
addRxPlugin(RxDBUpdatePlugin);

type Collections = {
  trade_route: TradeRouteCollectionType;
};

export type Database = RxDatabase<Collections>;

const createCollections = (db: RxDatabase) =>
  new Promise((res) => {
    const collectionPromises = [db.collection(TradeRouteCollection)];

    Promise.all(collectionPromises)
      .then(() => {
        res(db);
      })
      .catch((e) => {
        if (e.code === 'DB6') {
          db.remove();
          window.location.reload();
        } else {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      });
  });

export default new Promise<Database>((resolve) => {
  createRxDatabase({
    name: 'anno', // <- name
    adapter: 'idb', // <- storage-adapter
  })
    .then(createCollections)
    .then((db: any) => {
      resolve(db);
    });
});
