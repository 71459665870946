import React from 'react';
import { Input } from 'releox-react';

export default (): JSX.Element => (
  <>
    <Input name="name" label="Name" />
    <Input
      name="tradeTimeList"
      label="Trade time list"
      placeholder="eg. 12,23,43"
    />
  </>
);
