import { RxCollection, RxDocument } from 'rxdb';
import TradeRoute from '../../store/trade-route/TradeRoute';

const schema = {
  version: 0,
  indexes: ['averageTradeTime', 'id', 'name'],
  name: 'TradeRoute',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    name: {
      type: 'string',
    },
    tradeTimeList: {
      type: 'string',
    },
    averageTradeTime: {
      type: 'number',
    },
    createdAt: {
      type: 'string',
    },
    updatedAt: {
      type: 'string',
    },
  },
  required: ['name'],
};

export type TradeRouteCollectionType = RxCollection<TradeRoute>;
export type TradeRouteDocumentType = RxDocument<TradeRoute>;

export default {
  name: 'trade_route',
  schema,
};
