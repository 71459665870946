import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Card, Details } from 'releox-react';
import TradeRouteColumns from '../../columns/TradeRouteColumns';
import TradeRouteDemand from '../../components/TradeRouteDemand';
import routes from '../../configs/routes';
import TradeRoutesAction from '../../store/trade-route/TradeRoutesAction';
import TradeRoutesSelector from '../../store/trade-route/TradeRoutesSelector';

export default (): JSX.Element => {
  const dispatch = useDispatch();
  const tradeRoute = useSelector(TradeRoutesSelector.selectTradeRoute);
  const params = useParams<{ id: string }>();

  useEffect((): void => {
    dispatch(TradeRoutesAction.findById(params.id));
  }, [dispatch, params.id]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <Card>
            <Link
              to={routes.TRADE_ROUTE.INDEX}
              className="btn btn-primary float-right ml-2"
            >
              Index
            </Link>
            <Link
              to={routes.TRADE_ROUTE.EDIT.replace(':id', tradeRoute.id)}
              className="btn btn-primary float-right"
            >
              Edit
            </Link>
            <Details
              properties={[
                TradeRouteColumns.name,
                TradeRouteColumns.averageTradeTime,
                TradeRouteColumns.tradeTimeList,
              ]}
              object={tradeRoute}
            />
          </Card>
        </div>
        <div className="col-6">
          <TradeRouteDemand />
        </div>
      </div>
    </div>
  );
};
