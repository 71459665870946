import { push } from 'react-router-redux';
import { RednotAction } from 'rednot';
// import { RednotAction } from 'rednot';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import routes from '../../configs/routes';
import tradingAverageCalculator from '../../utils/trading-average-calculator/trading-average-calculator';
import TradeRoute from './TradeRoute';
import TradeRoutesEffect from './TradeRoutesEffect';

export default class TradeRoutesAction {
  static ENTRY = 'TRADE_ROUTE';

  static actionCreator = actionCreator(
    TradeRoutesAction.ENTRY,
    TradeRoutesEffect.restRequests,
    push,
    RednotAction.onError
  );

  static create = TradeRoutesAction.actionCreator.getCreateAction(
    routes.TRADE_ROUTE.INDEX,
    (body: TradeRoute): Promise<TradeRoute> => {
      return Promise.resolve({
        ...body,
        averageTradeTime: tradingAverageCalculator(body.tradeTimeList),
      });
    }
  );

  static update = TradeRoutesAction.actionCreator.getUpdateAction(
    routes.TRADE_ROUTE.INDEX,
    (body: Partial<TradeRoute>): Promise<Partial<TradeRoute>> => {
      return Promise.resolve({
        ...body,
        averageTradeTime: body.tradeTimeList
          ? tradingAverageCalculator(body.tradeTimeList)
          : 0,
      });
    }
  );

  static findById = TradeRoutesAction.actionCreator.getFindByIdAction();

  static delete = TradeRoutesAction.actionCreator.getDeleteAction(
    routes.TRADE_ROUTE.INDEX
  );

  static find = TradeRoutesAction.actionCreator.getFindAndCountAction();

  static clean = TradeRoutesAction.actionCreator.getCleanAction();
}
