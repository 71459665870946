import TradeRoute from '../../store/trade-route/TradeRoute';

const TradeRouteInitialValues: TradeRoute = {
  id: '',
  name: '',
  averageTradeTime: 0,
  tradeTimeList: '',
};

export default TradeRouteInitialValues;
