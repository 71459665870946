/* eslint-disable */
import React from 'react';
import { DataTableColumn, GenericIndexScene } from 'releox-react';
import routes from '../../configs/routes';
import TradeRoutesAction from '../../store/trade-route/TradeRoutesAction';
import TradeRoutesSelector from '../../store/trade-route/TradeRoutesSelector';

export default (): JSX.Element => {
  const columns: DataTableColumn[] = [
    {
      text: 'Name',
      dataField: 'name',
      sort: true,
    },
    {
      text: 'Average travel time',
      dataField: 'averageTradeTime',
      sort: true,
    },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <GenericIndexScene
            tNamespace="TradeRouteIndexScene"
            loadingSelector={TradeRoutesSelector.selectTradeRoutesIsLoading}
            dataSelector={TradeRoutesSelector.selectTradeRoutes}
            dataSizeSelector={TradeRoutesSelector.selectTradeRoutesCount}
            onChangeLoopback={TradeRoutesAction.find}
            redirectUrl={routes.TRADE_ROUTE.SHOW}
            createLink={routes.TRADE_ROUTE.CREATE}
            columns={columns}
            defaultSorted={{ dataField: 'averageTradeTime', order: 'asc' }}
          />
        </div>
      </div>
    </div>
  );
};
