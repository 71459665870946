import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardTitle } from 'releox-react';
import TradeRoutesSelector from '../store/trade-route/TradeRoutesSelector';

export default (): JSX.Element => {
  const [demand, setDemand] = useState('');
  const tradeRoute = useSelector(TradeRoutesSelector.selectTradeRoute);

  const handleDemandOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (!Number.isNaN(parseFloat(value))) {
        setDemand((parseFloat(value) * tradeRoute.averageTradeTime).toFixed(2));
      }
    },
    [setDemand, tradeRoute.averageTradeTime]
  );

  return (
    <Card>
      <CardTitle>Demand</CardTitle>
      <input onChange={handleDemandOnChange} type="number" />
      <p>{demand}</p>
    </Card>
  );
};
