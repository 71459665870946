import { rednotReducer } from 'rednot';
import { combineReducers } from 'redux';
import TradeRoutesReducer from './trade-route/TradeRoutesReducer';

const rootReducer = combineReducers({
  tradeRoute: TradeRoutesReducer.reducer,
  rednot: rednotReducer,
});

export type ReducerReturn = ReturnType<typeof rootReducer>;

export default rootReducer;
