import { reducerCreator } from 'redux-rest-helper-for-loopback';
import TradeRouteInitialValues from '../../forms/trade-route/TradeRouteInitialValues';
import TradeRoutesAction from './TradeRoutesAction';

export default class TradeRoutesReducer {
  private static initValues = TradeRouteInitialValues;

  public static reducer = reducerCreator(
    TradeRoutesAction.ENTRY,
    TradeRoutesReducer.initValues
  ).getReducer();
}
